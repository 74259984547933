.business-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: max-content;

    svg {
        width: 100%;
        height: auto;
    }

    .w360, .w480, .w768, .w1024, .w1440, .w1920 {
        display: none;
    }
    
}

@media (min-width: 360px) {
    .business-body {
        .w320 {
            display: none;
        }
        .w360 {
            display: block;
        }
    }
}

@media (min-width: 768px) {
    .business-body {
        .w360 {
            display: none;
        }
        .w768 {
            display: block;
        }
    }
}

@media (min-width: 1024px) {
    .business-body {
        .w768 {
            display: none;
        }
        .w1024 {
            display: block;
        }
    }
}

@media (min-width: 1440px) {
    .business-body {
        .w1024 {
            display: none;
        }
        .w1440 {
            display: block;
        }
    }
}

@media (min-width: 1920px) {
    .business-body {
        .w1440 {
            display: none;
        }
        .w1920 {
            display: block;
        }
    }
}