@import "../../../styles/theme.scss";

section.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 142px;
    height: max-content;
    min-height: 400px;
    width: 100%;
    padding: 72px 24px 48px 24px;
    background: linear-gradient(269.65deg, #262840 0.91%, #000323 139.32%);

    .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        width: 100%;
        height: max-content;

        h1 {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: fit-content;
            max-width: 272px;
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            color: #F5F5FA;
        }

        h4 {
            margin: 0;
            width: fit-content;
            max-width: 240px;
            font-size: 24px;
            font-weight: 500;
            line-height: 31px;
            color: #FFFFFF;
        }
    }

    .inicio {
        padding-top: 102px;
    }

    .button {
        display: flex;
        align-items: center;
        gap: 16px;
        width: fit-content;
        height: 60px;
        padding: 18px 12px;
        text-decoration: none;
        border: none;
        border-radius: 16px;
        background: #4C58FA;


        p {
            margin: 0;
            padding: 0;

            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #FFFFFF;
        }

        svg {
            width: 29.5px;
            height: 14px;
        }
    }

    .button:hover {
        background: #FF5033;
    }

}

@media (min-width: 540px) {
    section.container {
        gap: 248;
        padding: 154px 74px 74px 74px;

        .title {
            gap: 56px;

            h1 {
                max-width: none;
                font-size: 80px;
                line-height: 100px;
            }

            h4 {
                max-width: none;
                font-size: 32px;
                font-weight: 700;
                line-height: 48px;
            }
        }

        .button {
            gap: 24px;
            padding: 32px 18px;

            p {
                font-size: 24px;
                line-height: 31px;
            }

            svg {
                width: 29.5px;
                height: 14px;
            }
        }
    }
}

@media (min-width: 768px) {
    section.container {
        justify-content: flex-start;
        gap: 56px;
        padding: 278px 9.635vw 9.635vw 9.635vw;
        min-height: 620px;

        .title {
            flex: none;
            gap: 0;

            h1 {
                max-width: none;
                font-size: 64px;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
            }
        }

        .inicio {
            padding-top: 0;
        }
    }
}

@media (min-width: 1024px) {
    section.container {
        gap: 56px;
        padding: 278px 9.766vw 9.766vw 9.766vw;
        min-height: 620px;

        .title {
            flex: none;
            gap: 0;

            h1 {
                flex-direction: row;
                gap: 16px;
                max-width: none;
                font-size: 64px;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 1440px) {
    section.container {
        gap: 56px;
        // min-height: 1024px;

        .title {
            flex: none;
            gap: 0;

            h1 {
                max-width: none;
                font-size: 64px;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 1920px) {
    section.container {
        gap: 56px;
        padding: 278px 18.541vw;
        // min-height: 1024px;

        .title {
            flex: none;
            gap: 0;

            h1 {
                max-width: none;
                font-size: 64px;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}