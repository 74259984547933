.light {
    --theme-page-background: #e4e4e4;
    --theme-section: #dadce0;
    --theme-service: #cad1db;
    --theme-page-text: #000323;
    --theme-blue: #4557ff;
    --theme-orange: #ff5033;
    --theme-cian: #48ddff;
}

.dark {
    --theme-page-background: #262840;
    --theme-section: #121a3f;
    --theme-service: #283560;
    --theme-page-text: #F5F5FA;
    --theme-blue: #4557ff;
    --theme-orange: #ff5033;
    --theme-cian: #48ddff;
}