.bm-container {
    position: relative;
    margin: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    .bm-icon {
        margin: 0;
        padding: 0;
        position: absolute;
        // z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: auto;

        .bm-line1, .bm-line3 {
            transform-box: fill-box;
            transform-origin: center;
        }
    }
}

.open {
    .bm-icon {
        .bm-line1 {
            animation: playLine1 300ms forwards;
        }
        .bm-line2 {
            animation: playLine2 300ms forwards;
        }
        .bm-line3 {
            animation: playLine3 300ms forwards;
        }
    }
}

.close {
    .bm-icon {
        .bm-line1 {
            animation: backLine1 300ms forwards;
        }
        .bm-line2 {
            animation: backLine2 300ms forwards;
        }
        .bm-line3 {
            animation: backLine3 300ms forwards;
        }
    }
}

@keyframes playLine1 {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(6px) rotate(0deg);
    }
    100% {
        transform: translateY(6px) rotate(45deg);
    }
}
  
@keyframes backLine1 {
    0% {
        transform: translateY(6px) rotate(45deg);
    }
    50% {
        transform: translateY(6px) rotate(0deg);
    }
    100% {
        transform: translateY(0px) rotate(0deg);
    }
}
  
@keyframes playLine2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
  
@keyframes backLine2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
  
@keyframes playLine3 {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-6px) rotate(0deg);
    }
    100% {
        transform: translateY(-6px) rotate(-45deg);
    }
}
  
@keyframes backLine3 {
    0% {
        transform: translateY(-6px) rotate(-45deg);
    }
    50% {
        transform: translateY(-6px) rotate(0deg);
    }
    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

@media (min-width: 540px) {
    .bm-container {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 980px) {
    .bm-container {
        display: none;
    }
}