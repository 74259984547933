@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
}

html {
    scroll-behavior: smooth
}

*::selection {
  background-color: #FF5033;
  color: #FFFFFF;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000323;
}
