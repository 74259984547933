.resources-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: max-content;

    svg {
        width: 100%;
        height: auto;
    }

    .sectores-industriales {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 7.5vw;
        padding-right: 7.5vw;

        h2 {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
        }

        .cards {
            width: auto;
            margin-left: -7.5vw;
            margin-right: -7.5vw;
            padding-left: 5vw;
            padding-right: 5vw;
            // background-color: red;
            overflow-x: scroll;
            scrollbar-width: none;
            cursor: grab;

            svg {
                width: auto;
                height: auto;
            }
        }

        .cards::-webkit-scrollbar {
            display: none;
        }

        .cards.active {
            cursor: grabbing;
        }
    }

    .w360, .w480, .w768, .w1024, .w1440, .w1920 {
        display: none;
    }
    
}

@media (min-width: 360px) {
    .resources-body {
        .w320 {
            display: none;
        }
        .w360 {
            display: block;
        }
    }
}

@media (min-width: 768px) {
    .resources-body {
        .w360 {
            display: none;
        }
        .w768 {
            display: block;
        }

        .sectores-industriales {
            padding-left: 9.6vw;
            padding-right: 9.6vw;
    
            h2 {
                font-size: 56px;
                line-height: 70px;
            }
    
            .cards {
                margin-left: -9.6vw;
                margin-right: -9.6vw;
                padding-left: 8.6vw;
                padding-right: 8.6vw;
            }
        }
    }
}

@media (min-width: 1024px) {
    .resources-body {
        .w768 {
            display: none;
        }
        .w1024 {
            display: block;
        }
    }
}

@media (min-width: 1440px) {
    .resources-body {
        .w1024 {
            display: none;
        }
        .w1440 {
            display: block;
        }
    }
}

@media (min-width: 1920px) {
    .resources-body {
        .w1440 {
            display: none;
        }
        .w1920 {
            display: block;
        }

        .sectores-industriales {
            padding-left: 18.541vw;
            padding-right: 18.541vw;
    
            .cards {
                margin-left: -18.541vw;
                margin-right: -18.541vw;
                padding-left: 18.125vw;
                padding-right: 18.125vw;
            }
        }
    }
}