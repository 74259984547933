.home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: max-content;

    svg {
        width: 100%;
        height: auto;
    }

    .w360, .w480, .w768, .w1024, .w1440, .w1920 {
        display: none;
    }
    
}


#scada:hover * {
    cursor: pointer;
}

#metering:hover * {
    cursor: pointer;
}

#scada:hover {
    #background-scada {
        fill: url(#paint2_linear_1184_4710);
    }
    .toggle-fill {
        fill: #F5F5FA;
    }
}
#metering:hover {
    #background-metering {
        fill: url(#paint3_linear_1184_4710);
    }
    .toggle-fill {
        fill: #F5F5FA;
    }
}



@media (min-width: 360px) {
    .home-body {
        .w320 {
            display: none;
        }
        .w360 {
            display: block;
        }
    }
    #scada:hover {
        #background-scada {
            fill: url(#paint2_linear_1184_4709);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
    #metering:hover {
        #background-metering {
            fill: url(#paint3_linear_1184_4709);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
}

@media (min-width: 768px) {
    .home-body {
        .w360 {
            display: none;
        }
        .w768 {
            display: block;
        }
    }
    #scada:hover {
        #background-scada {
            fill: url(#paint2_linear_1183_4708);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
    #metering:hover {
        #background-metering {
            fill: url(#paint3_linear_1183_4708);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
}

@media (min-width: 1024px) {
    .home-body {
        .w768 {
            display: none;
        }
        .w1024 {
            display: block;
        }
    }
    #scada:hover {
        #background-scada {
            fill: url(#paint2_linear_1183_4707);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
    #metering:hover {
        #background-metering {
            fill: url(#paint3_linear_1183_4707);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
}

@media (min-width: 1440px) {
    .home-body {
        .w1024 {
            display: none;
        }
        .w1440 {
            display: block;
        }
    }

    #scada:hover {
        #background-scada {
            fill: url(#paint2_linear_1183_4706);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
    #metering:hover {
        #background-metering {
            fill: url(#paint3_linear_1183_4706);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
}

@media (min-width: 1920px) {
    .home-body {
        .w1440 {
            display: none;
        }
        .w1920 {
            display: block;
        }
    }

    #scada:hover {
        #background-scada {
            fill: url(#paint2_linear_1183_4705);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
    #metering:hover {
        #background-metering {
            fill: url(#paint3_linear_1183_4705);
        }
        .toggle-fill {
            fill: #F5F5FA;
        }
    }
}