@import "../../styles/theme.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    background: var(--theme-page-background);
    color: var(--theme-page-text);

    header {
        display: flex;
        justify-content: center;

        width: 100%;
        height: 48px;
        padding: 12px 24px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(269.65deg, #262840 0.91%, #000323 139.32%);

        .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            // background-color: yellow;

            .logo {
                height: 100%;
                width: auto;
            }

            nav {
                position: fixed;
                top: 0;
                left: 0;
                
                flex-direction: column;
                align-items: center;
                justify-content: center;

                padding: 48px 24px;
                width: 100%;
                height: 100%;

                background-color: #4C58FA;

                ul {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 20px;
                    padding: 0 0 0 32px;
                    width: 100%;
                    list-style-type: none;
                    
                    li, a {
                        text-decoration: none;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 5%;
                        color: #F5F5FA;
                    }

                    a.active {
                        font-weight: 700;
                    }

                    a:hover{
                        font-weight: 700;
                    }
                }

                svg {
                    align-self: flex-start;
                    width: 113px;
                    height: auto;
                }
            }

            .navigation.open {
                display: flex;
            }

            .navigation.close {
                display: none;
            }
        }
    }

    .panels {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #contacto {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        width: 100%;
        height: 100%;
        padding: 48px 24px;
        background-color: #F5F5FA;

        div {
            display: flex;
            flex-direction: column;
            gap: 26px;
            width: 100%;

            h3 {
                margin: 0;
                font-size: 24px;
                font-weight: 700;
                color: #202133;
            }

            p {
                margin: 0;
                font-size: 20px;
                font-weight: 500;
                color: #262840;
            }

            div {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 37px;
        width: 100%;
        background-color: #F5F5FA;
        padding: 24px;

        .blue-bar {
            width: 100%;
            height: 4px;
            background-color: #4C58FA;
        }

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            svg {
                width: 120px;
                height: auto;
            }
            p {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                color: #262840;
            }
        }
    }

    h1 {
        font-weight: 700;
    }
}

@media (min-width: 540px) {
    .container {
        header {
            height: 80px;
            padding: 20px 40px;

            .content {
                nav {
                    padding: 72px 40px;

                    ul {
                        padding: 0 0 0 10vw;
                        gap: 48px;

                        li, a {
                            font-size: 36px;
                            letter-spacing: 0.05em;
                        }
                    }

                    svg {
                        width: 173px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .container {
        #contacto {
            padding: 11.71vw 9.63vw;
    
            div {
                h3 {
                    font-size: 32px;
                }
            }
        }
    
        footer {
            padding: 5.85vw 9.63vw;
        }
    }
}

@media (min-width: 980px) {
    .container {
        header {

            height: 122px;
            padding: 40px 60px 40px 40px;
            position: absolute;

            .content {
                nav {
                    position: relative;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    width: auto;
                    height: auto;
                    background-color: transparent;

                    ul {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 35px;
                        padding: 0;
                        width: auto;
                        list-style-type: none;

                        li, a {
                            font-family: 'Roboto', sans-serif;
                            font-size: 16px;
                            letter-spacing: 0;
                            color: #E9E9F2;
                        }

                        a.active {
                            font-weight: 400;
                            color: #67B6FC;
                        }
    
                        a:hover{
                            font-weight: 400;
                            color: #67B6FC;
                        }
                    }

                    svg {
                        display: none;
                    }
                }

                .navigation.open {
                    display: flex;
                }

                .navigation.close {
                    display: flex;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .container {
        #contacto {
            gap: 96px;
            padding: 9.76vw;
    
            div {
                h3 {
                    font-size: 24px;
                }

                div {
                    p {
                        font-size: 24px;
                        font-weight: 500;
                    }
                }

                p {
                    font-size: 56px;
                    font-weight: 700;
                }
                
            }
        }
    
        footer {
            padding: 5.85vw 9.76vw;
        }
    }
}

@media (min-width: 1920px) {
    .container {
        #contacto {
            gap: 96px;
            padding: 7.29vw 18.54vw;
    
            div {
                h3 {
                    font-size: 24px;
                }

                div {
                    p {
                        font-size: 24px;
                        font-weight: 500;
                    }
                }

                p {
                    font-size: 56px;
                    font-weight: 700;
                }
                
            }
        }
    
        footer {
            padding: 4.16vw 18.54vw 2vw 18.54vw;
        }
    }
}